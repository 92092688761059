import { CheckingAccess, NoAccess } from "@components/layout/non-ideal-states"
import { AnimatePresence, motion, useIsPresent } from "framer-motion"
import { PropsWithChildren, useMemo, useState } from "react"
import { useMembershipStatus } from "../hooks/useAuth"

type Props = PropsWithChildren<{
  enforce?: boolean | null | undefined
  backUrl?: string
}>

export function MembersOnlyContent(props: Props) {
  const present = useIsPresent()
  const status = useMembershipStatus()
  let hadToCheck = useMemo(() => status.loading && props.enforce, [])

  let child = <>{props.children}</>
  let childKey = "content"

  if (props.enforce) {
    if (status.loading) {
      child = <CheckingAccess />
      childKey = "checking"
      hadToCheck = true
    } else if (!status.hasAccess) {
      child = <NoAccess backUrl={props.backUrl} />
      childKey = "no-access"
      hadToCheck = true
    }
  }

  if (hadToCheck) {
    return (
      <AnimatePresence mode="wait">
        {present && (
          <motion.div key={childKey} variants={{ hide: { opacity: 0 }, show: { opacity: 1 } }} initial="hide" exit="hide" animate="show">
            {child}
          </motion.div>
        )}
      </AnimatePresence>
    )
  } else {
    return child
  }
}
